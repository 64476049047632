export const Relatorio = {
  LISTAR_RELATORIOS: "Listar Relatórios",
  OPERACAO: "Operação",
  ABERTOLIQUIDADO: "Abertos/Liquidados",
  RELATORIOPROGRAMACAOPAGAMENTO: "Programações",
  RELATORIOCONTAGRAFICA: "Conta Gráfica",
  RELATORIORECOMPRA: "Recompra",
  CPFCNPJ: "CPF ou CNPJ do Cedente",
  DE: "Data de início",
  ATE: "Data de fim",
  STATUS: "Status",
  TOTALDEBITOS: "Total de débitos:",
  CAMPOS_TABELA: {
    NOME_CEDENTE: "Cedente",
    CNPJ_CEDENTE: "CNPJ",
    NOME_EMPRESA: "Nome Empresa",
    COD_CONSULTOR: "Cód. Consultor",
    DATA_POSICAO: "Data Posição",
    COD_EMPRESA: "Cód. Empresa",
    COD_FILIAL: "Cód. Filial",
    COD_CEDENTE: "Cód. Cedente",
    DATA_LANCAMENTO: "Data Lançamento",
    NUMERO_DIAS_LANCAMENTO: "Nº Dias Lançamento",
    COD_EVENTO: "Cód. Evento",
    NOME_EVENTO: "Tipo Evento",
    DESCRICAO_EVENTO: "Descrição do Evento",
    DESCRICAO_COMPLEMENTO: "Descrição Grupo",
    LANCAMENTO_MANUAL: "Lançamento Manual",
    DATA_ATUALIZACAO: "Data Atualização",
    VALOR_DEBITO: "Valor Débito",
    VALOR_CREDITO: "Valor Crédito",
    VALOR_SALDO: "Valor Saldo",
    VALOR_ENCARGO: "Valor Encargo",
  },
  RECOMPRA: {
    DATA_RECOMPRA: "Data Recompra",
    DATA_VENCIMENTO: "Data Vencimento do Título",
    NOME_SACADO: "Nome do Sacado",
    NUMERO: "Seu Número",
    DATA_MOVIMENTO: "Data do Movimento",
    DESCRICAO: "Descrição",
    VALOR: "Valor de Recompra",
  }
}