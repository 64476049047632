import Vue from 'vue';
import Router from 'vue-router';
import verificaAutenticacao from '@/common/middleware/verificaAutenticacao.js';
import verificaAutenticacaoSacado from '@/common/middleware/verificaAutenticacaoSacado.js';
import redirecionaSeAutenticado from '@/common/middleware/redirecionaSeAutenticado.js';
import redirecionaComercial from '@/common/middleware/redirecionaComercial.js';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,

  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      component: () => import('@/layouts/full-layout/FullLayout'),
      children: [
        // dashboard
        {
          name: 'dashboard',
          path: '/dashboard',
          meta: {
            middleware: [verificaAutenticacao, redirecionaComercial]
          },
          component: () =>
            import('@/views/dashboards/Dashboard'),
        },
        {
          name: 'DashboardComercial',
          path: '/dashboard-comercial',
          component: () => import('@/views/dashboards/DashboardComercial'),
        },
        {
          name: 'operacoes',
          path: '/operacoes',
          meta: {
            middleware: verificaAutenticacao,
          },
          beforeEnter: (to, from, next) => {
            verificarPermissao(next, 'Operacao');
          },
          component: () => import('@/views/operacoes/Index'),
          props: true
        },
        {
          name: 'boletos',
          path: '/titulos',
          meta: {
            middleware: verificaAutenticacao,
          },
          beforeEnter: (to, from, next) => {
            verificarPermissao(next, 'Titulos');
          },
          component: () => import('@/views/boletos/Boletos'),
        },
        {
          name: 'boletos_comercial',
          path: '/titulos-comercial',
          meta: {
            middleware: verificaAutenticacao,
          },
          beforeEnter: (to, from, next) => {
            verificarPermissao(next, 'Comercial');
          },
          component: () => import('@/views/boletos/BoletosComercial'),
        },
        {
          name: 'programacao',
          path: '/programacao',
          meta: {
            middleware: verificaAutenticacao,
          },
          beforeEnter: (to, from, next) => {
            verificarPermissao(next, 'ProgramacaoPagamento');
          },
          component: () => import('@/views/programacao/TitulosTabs'),
        },
        {
          name: 'relatorio',
          path: '/relatorio',
          meta: {
            middleware: verificaAutenticacao,
          },
          beforeEnter: (to, from, next) => {
            verificarPermissao(next, 'RelatorioCedente');
          },
          component: () =>
            import('@/views/relatorio/Index'),
        },
        {
          name: 'relatorio_cedente',
          path: '/relatorio_cedente',
          meta: {
            middleware: verificaAutenticacao,
          },
          beforeEnter: (to, from, next) => {
            verificarPermissao(next, 'RelatorioCedente');
          },
          component: () =>
            import('@/views/relatorio/Index'),
        },
        {
          name: 'instrucoes',
          path: '/instrucoes',
          meta: {
            middleware: verificaAutenticacao,
          },
          beforeEnter: (to, from, next) => {
            verificarPermissao(next, 'Instrucoes');
          },
          component: () => import('@/views/instrucoes/Index'),
        },
        //usuários
        {
          name: 'usuarios',
          path: '/usuarios',
          meta: {
            middleware: verificaAutenticacao,
          },
          beforeEnter: (to, from, next) => {
            verificarPermissao(next, 'Usuario');
          },
          component: () => import('@/views/usuarios/Index'),
        },
        {
          name: 'auditoria',
          path: '/auditoria',
          meta: {
            middleware: verificaAutenticacao,
          },
          beforeEnter: (to, from, next) => {
            verificarPermissao(next, 'Auditoria');
          },
          component: () => import('@/views/auditoria/Index'),
        },
        {
          name: 'comunicado',
          path: '/comunicado',
          meta: {
            middleware: verificaAutenticacao,
          },
          beforeEnter: (to, from, next) => {
            verificarPermissao(next, 'Comunicado');
          },
          component: () => import('@/views/comunicado/Index'),
        },
        {
          name: 'criar-comunicado',
          path: '/criar-comunicado',
          meta: {
            middleware: verificaAutenticacao,
          },
          beforeEnter: (to, from, next) => {
            verificarPermissao(next, 'Comunicado');
          },
          component: () => import('@/views/comunicado/comunicado'),
        },
        {
          name: 'editar-comunicado',
          path: '/editar-comunicado',
          meta: {
            middleware: verificaAutenticacao,
          },
          beforeEnter: (to, from, next) => {
            verificarPermissao(next, 'Comunicado');
          },
          component: () => import('@/views/comunicado/comunicado'),
        },
        {
          name: 'usuarios-formulario',
          path: '/usuarios/form/:id?/',
          meta: {
            middleware: verificaAutenticacao,
          },
          beforeEnter: (to, from, next) => {
            verificarPermissao(next, 'Usuario');
          },
          component: () => import('@/views/usuarios/Formulario'),
        },
        {
          name: 'minha-conta',
          path: '/minha-conta',
          meta: {
            middleware: verificaAutenticacao,
          },
          component: () => import('@/views/usuarios/MinhaConta'),
        },
        {
          name: 'acesso-sacado',
          path: '/acesso-sacado',
          meta: {
            middleware: verificaAutenticacaoSacado,
          },
          component: () => import('@/views/acessosacado/AcessoSacado'),
        },

        {
          name: 'permissoes',
          path: '/permissoes',
          meta: {
            middleware: verificaAutenticacao,
          },
          beforeEnter: (to, from, next) => {
            verificarPermissao(next, 'Perfil');
          },
          component: () => import('@/views/permissoes/Index'),
        },
        {
          name: 'status',
          path: '/status',
          meta: {
            middleware: verificaAutenticacao,
          },
          component: () => import('@/views/status/Index'),
        },
        {
          name: 'permissoes-formulario',
          path: '/permissoes/form/:id?/',
          meta: {
            middleware: verificaAutenticacao,
          },
          beforeEnter: (to, from, next) => {
            verificarPermissao(next, 'Perfil');
          },
          component: () => import('@/views/permissoes/Formulario'),
        },
        {
          name: 'arquivo-retorno',
          path: '/arquivo-retorno',
          meta: {
            middleware: verificaAutenticacao,
          },
          beforeEnter: (to, from, next) => {
            verificarPermissao(next, 'ArquivoRetorno');
          },
          component: () => import('@/views/arquivo-retorno/Index'),
        },
        // rotulo boletos
        {
          name: "rotulo-boletos",
          path: "/rotulo-boletos",
          meta: {
            middleware: verificaAutenticacao,
          },
          beforeEnter: (to, from, next) => {
            verificarPermissao(next, "RotuloBoletos");
          },
          component: () => import("@/views/rotulo-boletos/Index"),
        },
        {
          name: "rotulo-boletos-novo",
          path: "/rotulo-boletos/novo",
          meta: {
            middleware: verificaAutenticacao,
          },
          beforeEnter: (to, from, next) => {
            verificarPermissao(next, "RotuloBoletos");
          },
          component: () => import("@/views/rotulo-boletos/Formulario"),
        },
        {
          name: "rotulo-boletos-editar",
          path: "/rotulo-boletos/:id?",
          meta: {
            middleware: verificaAutenticacao,
          },
          beforeEnter: (to, from, next) => {
            verificarPermissao(next, "RotuloBoletos");
          },
          props: true,
          component: () => import("@/views/rotulo-boletos/Formulario"),
        },

        {
          name: 'visita-relacionamento',
          path: '/visita-relacionamento',
          meta: {
            middleware: verificaAutenticacao,
          },
          component: () => import('@/views/comercial/bitrix/visitas-relacionamento/Index'),
        },
        {
          name: 'cadastro-clientes-valorem',
          path: '/cadastro-clientes-valorem',
          meta: {
            middleware: verificaAutenticacao,
          },
          component: () => import('@/views/comercial/bitrix/cadastro-clientes/Index'),
        },
        {
          name: 'desempenho-comercial',
          path: '/desempenho-comercial',
          meta: {
            middleware: verificaAutenticacao,
          },
          component: () => import('@/views/comercial/desempenho-comercial/Index'),
        },
        {
          name: 'visita-prospeccao',
          path: '/visita-prospeccao',
          meta: {
            middleware: verificaAutenticacao,
          },
          component: () => import('@/views/comercial/bitrix/visitas-prospeccao/Index'),
        },
        {
          name: 'bitrix',
          path: '/bitrix',
          meta: {
            middleware: verificaAutenticacao,
          },
          component: () => import('@/views/comercial/bitrix/Index'),
        },
        {
          name: 'cadastro-lead',
          path: '/cadastro-lead',
          meta: {
            middleware: verificaAutenticacao,
          },
          component: () => import('@/views/comercial/bitrix/cadastro-lead/Index'),
        },
        {
          name: 'vadu',
          path: '/vadu',
          meta: {
            middleware: verificaAutenticacao,
          },
          component: () => import('@/views/comercial/vadu/Index'),
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/layouts/blank-layout/Blanklayout'),
      children: [
        {
          name: 'Login',
          path: 'login',
          meta: {
            middleware: redirecionaSeAutenticado,
          },
          component: () => import('@/views/autenticacao/Login'),
        },
        {
          name: 'Cadastro-Sacado',
          path: 'cadastro-sacado',
          meta: {
            middleware: redirecionaSeAutenticado,
          },
          component: () => import('@/views/autenticacao/Login'),
        },

        {
          name: 'Cadastro',
          path: 'cadastro',
          meta: {
            middleware: redirecionaSeAutenticado,
          },
          component: () => import('@/views/autenticacao/Cadastro'),
        },
        {
          name: 'esqueceu-sua-senha',
          path: 'esqueceu-sua-senha/',
          meta: {
            middleware: redirecionaSeAutenticado,
          },
          component: () => import('@/views/autenticacao/EsqueceuSenha'),
        },
      ],
    },
    {
      path: '*',
      redirect: '/login',
      //component: () => import("@/views/authentication/Error"),
    },
    {
      path: '/',
      redirect: '/login',
      //component: () => import("@/views/authentication/Error"),
    },
  ],
});

import NProgress from 'nprogress';
import verificarPermissao from '../common/middleware/verificarPermissao';
//import verificarPermissaoComercial from '../common/middleware/verificarPermissaoComercial';

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  NProgress.done();
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];

  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
